<template>
    <div v-frag >
      <div class="container-fluid">
        <div class="row" style="padding-bottom:60px">
          <div class="col-md-2 hidden-sm hidden-xs"></div>
          <div class="col-md-8">
            <p class="visible-xs"></p>
            <the-carousel :slides="casinoSlides"/>
            <div class="">
              <input type="text" v-model="search"  class="form-control" :placeholder="`${$t('betslip.search')}`">
            </div>
            <lobbyCasinoVue :ids="['1301','1320']" />
            
          </div>
          <div class="col-md-2 hidden-sm hidden-xs"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import "vue-loading-overlay/dist/vue-loading.css";
  import lobbyCasinoVue from "./lobby.casino.vue";

  
  export default {
    name: "home_casino",
    components:{
      lobbyCasinoVue,
    },
    data() {
        return {
            idd:[1301,1320],
            serch:''
        }
    },
    computed: {
      ...mapGetters(["casinos", "loadingSlots",'casinoSlides']),
    },
  };
  </script>
  
  <style scoped>
   .form-control {
      background-color: var(--bg-dark-medium);
      color: var(--a-text);
      border: 1px solid var(--border-color);
    }
    .casino {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .casino-card {
      border: 1px solid #494d59;
      border-radius: 10px;
      position: relative;
      /* background-color: #494d59 ; */
    }
    .casino-img {
      width: 100%;
      border-radius: 10px;
      max-height: 200px;
    }
    .casino-card-body {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border-radius: 10px;
      opacity: 0;
      background-color: rgb(0, 0, 0);
      transition: all 0.3s ease-in-out 0s;
      display: flex;
      justify-content: center;
      flex-direction: column;
      
    }
    .casino-card:hover .casino-card-body {
      opacity: 0.95;
      z-index: 200;
    }
    .casino-card-body .play {
      display: flex;
      justify-content: space-around;
    }
    .play-game {
      background-color: #f7941e;
      border: 1px solid #f7941e;
      color: #ffffff;
      font-weight: bold;
      font-size: 14px;
      padding: 4px 10px;
      opacity: 1;
    }
    .play-demo {
      background-color: #073b6b;
      border: 1px solid #073b6b;
      color: #ffffff;
      font-weight: bold;
      font-size: 14px;
      padding: 4px 10px;
    }
    .casino-card-body .hed {
      font-size: 20px;
      display: block;
    }
    ul li {
      cursor: pointer;
      /* font-weight: bold; */
    }
    .cat {
      padding: 10px;
    }
    .cat ul {
      white-space: nowrap;
      overflow-x: scroll;
      margin-bottom: 0px;
    }
    .cat span {
      display: inline-block;
      background-color: var(--bg-blue);
      padding: 4px 10px;
      border-radius: 15px;
      /* font-size: 12px; */
      cursor: pointer;
    }
    .cat span.select {
      background-color: var(--bg-orange);
    }
  
    @media screen and (min-width: 768px) {
      /* .casino-card-body {
        padding: 30px 10px;
      } */
      .casino-card-body .hed {
        font-size: 20px;
      }
    }
  
    @media screen and (max-width: 767px) {
      .casino-card-body .play {
        justify-content: space-between;
        flex-direction: column;
      }
      .casino-card-body {
        padding: 10px 5px;
      }
      .casino-card-body .hed {
        font-size: 16px;
      }
      .play-demo {
        margin-bottom: 5px;
      }
      .casino-img {
        min-height: 150px;
      }
      .featured-header.links {
        border-top: 0px;
      }
      .featured-header {
        background-color: transparent;
        padding: 5px ;
      }
      .featured-header ul {
        margin-bottom: 5px;
      }
        
  }
  </style>